import { StyleSheet } from '@react-pdf/renderer';
export const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    width: '100%'
  },
  content_activities_tasks: {
    // borderTop: '1pt solid #EBEBEB',
    marginLeft: 20,
    marginRight: 20
  },
  text: {
    fontSize: 20,
    padding: 2,
    backgroundColor: '#61C74F',
    fontFamily: 'Roboto',
    color: '#000'
  },
  section: {
    marginTop: 18,
    marginLeft: 5
  },
  title_head_week: {
    marginTop: 18,
    fontSize: 12,
    marginLeft: 450,
    color: '#2C3421'
  },
  subtitle_head_week: {
    fontSize: 10,
    marginLeft: 450
  },
  image: {
    width: 6,
    height: 6,
    marginLeft: 15
  },
  section_title: {
    marginTop: 18,
    marginLeft: 5,
    width: 100
  },
  title: {
    fontWeight: 500,
    fontSize: 14,
    color: '#121212',
    fontFamily: 'Roboto'
  },
  title_lean_state: {
    fontSize: 9,
    fontFamily: 'Roboto',
    color: '#2C3421',
    marginTop: 2
  },
  title_lean_state_com: {
    fontSize: 9,
    fontFamily: 'Roboto',
    color: '#F59D04',
    marginTop: 2
  },
  title_name: {
    fontSize: 10,
    minHeight: 12,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    color: '#A7A7A7',
    marginTop: 9,
    marginBottom: 9,
    paddingBottom: 0
  },
  answer_name: {
    fontSize: 10,
    minHeight: 12,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    color: '#121212'
  },
  table: {
    flexGrow: 1,
    flexDirection: 'column'
  },
  section_header: {
    flexDirection: 'row',
    marginRight: 20,
    marginLeft: 20,
    marginTop: 20,
    marginBottom: 0,
    border: '1pt solid #EBEBEB',
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    justifyContent: 'space-between'
  },
  section_footer: {
    flexDirection: 'row',
    margin: '0 20 0 20',
    borderLeft: 1,
    borderRight: 1,
    borderBottom: 1,
    borderColor: '#EBEBEB',
    borderStyle: 'solid',
    justifyContent: 'space-between'
  },
  section_container_header: {
    paddingTop: 5,
    paddingBottom: 5,
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 5,
    border: '1pt solid #FAFAFA'
  },
  section_header_table: {
    display: 'flex',
    flexDirection: 'row'
  },
  sub_section_columns_header_table: {
    display: 'flex',
    flex: 1.15,
    flexDirection: 'row'
  },
  sub_section_days_header_table: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  table_header: {
    borderRight: '1pt solid #EBEBEB'
  },
  table_header_days: {
    width: '18.1px',
    height: '12px !important',
    marginLeft: '1px',
    borderRight: '1pt solid #EBEBEB'
  },
  table_header_days_first: {
    width: 10,
    marginLeft: 10,
    backgroundColor: '#ffffff'
  },
  table_heade_title_name: {
    fontSize: 10,
    fontFamily: 'Roboto',
    color: '#121212',
    textAlign: 'center',
    fontStyle: 'normal',
    fontWeight: 500
    // lineHeight: 12
  },
  table_heade_title_name_date: {
    fontSize: 8,
    fontFamily: 'Roboto',
    textAlign: 'center',
    color: '#121212',
    marginTop: 1,
    fontStyle: 'normal',
    minHeight: 12,
    fontWeight: 500
    // lineHeight: 12
  },
  table_task_title_name_texto: {
    height: 'auto',
    width: '100%',
    fontSize: 8,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    color: '#121212'
  },
  table_task_title_name: {
    height: 'auto',
    width: '100%',
    fontSize: 10,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    color: '#121212',
    textAlign: 'center',
    margin: 'auto'
  },
  table_task_title_day: {
    height: 'auto',
    width: '100%',
    fontSize: 8,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    textAlign: 'center',
    margin: 'auto'
  },
  table_task__subcontract_box: {
    width: 10,
    height: 10,
    position: 'absolute',
    left: 5
  },
  table_task_title_name_parent: {
    height: 'auto',
    fontSize: 7,
    fontFamily: 'Roboto',
    marginLeft: 2,
    fontStyle: 'normal',
    fontWeight: '1200',
    color: '#000000'
  },
  table_heade_title_name_hour: {
    fontSize: 6,
    fontFamily: 'Roboto',
    padding: 0,
    color: '#121212',
    textAlign: 'center'
  },
  section_more: {
    fontSize: 6,
    fontFamily: 'Roboto',
    padding: 7,
    color: '#121212'
  },
  section_columns_activity: {
    backgroundColor: '#F5F5F5',
    borderBottom: '1pt solid #EBEBEB',
    borderLeft: '1pt solid #EBEBEB',
    display: 'flex',
    flexDirection: 'row',
    flex: 1.15,
    alignItems: 'center'
  },
  section_row_activity: {
    display: 'flex',
    flexDirection: 'row',
    height: 22
  },
  section_timeline_activity: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1
  },
  section_timeline_activity_per_day: {
    width: 19.11,
    marginLeft: 0,
    flexDirection: 'row',
    boxSizing: 'border-box',
    backgroundColor: '#F5F5F5',
    borderBottom: '1pt solid #EBEBEB',
    paddingBottom: '2px',
    paddingTop: '2px'
  },
  section_activity_title_name: {
    color: 'black',
    fontWeight: 'bold',
    fontSize: 8,
    fontFamily: 'Roboto',
    padding: '4px',
    paddingLeft: '10px',
    flex: 1
  },
  section_task_table: {
    display: 'flex',
    flexDirection: 'row'
  },
  sub_section_columns_task_table: {
    flex: 1.15,
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#CCA7C1',
    boxSizing: 'border-box',
    borderBottom: '1pt solid #EBEBEB',
    borderRight: '1pt solid #EBEBEB'
  },
  sub_section_days_task_table: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row'
  },
  section_task_parent: {
    marginLeft: 10,
    marginRight: 10,
    height: 'auto',
    boxSizing: 'border-box',
    borderBottom: '1pt solid #EBEBEB',
    borderRight: '1pt solid #EBEBEB',
    backgroundColor: '#EBEBEB'
  },
  table_task_with_icon: {
    flexDirection: 'row',
    borderLeft: '1pt solid #EBEBEB',
    paddingBottom: 2,
    paddingTop: 2
  },
  table_task_column: {
    borderLeft: '1pt solid #EBEBEB',
    paddingBottom: '2px',
    paddingTop: '2px'
  },
  table_task_texto_parent: {
    width: 107,
    height: 'auto',
    backgroundColor: '#F5F5F5',
    boxSizing: 'border-box',
    borderRight: '1pt solid #EBEBEB',
    borderLeft: '1pt solid #EBEBEB',
    paddingBottom: 2,
    paddingTop: 2,
    flexDirection: 'row'
  },
  table_task_priority: {
    width: 40,
    marginLeft: 5,
    flexDirection: 'row',
    boxSizing: 'border-box',
    borderRight: '1pt solid #EBEBEB',
    paddingBottom: 2,
    paddingTop: 2
  },
  table_task_priority_parent: {
    width: 40,
    marginLeft: 5,
    flexDirection: 'row',
    backgroundColor: '#E1E1E1',
    boxSizing: 'border-box',
    borderRight: '1pt solid #EBEBEB',
    paddingBottom: 2,
    paddingTop: 2
  },
  table_task_real: {
    width: 30,
    marginLeft: 5,
    flexDirection: 'row',
    boxSizing: 'border-box',
    borderRight: '1pt solid #EBEBEB',
    paddingBottom: 2,
    paddingTop: 2
  },
  table_task_real_parent: {
    width: 30,
    marginLeft: 5,
    flexDirection: 'row',
    backgroundColor: '#E1E1E1',
    boxSizing: 'border-box',
    borderRight: '1pt solid #EBEBEB',
    paddingBottom: 2,
    paddingTop: 2
  },
  open_roadblocks: {
    fontSize: 8,
    fontFamily: 'Roboto',
    textAlign: 'center',
    color: '#E50101',
    margin: 'auto',
    width: 15
  },
  removed_roadblocks: {
    fontSize: 8,
    fontFamily: 'Roboto',
    textAlign: 'center',
    color: '#121212',
    margin: 'auto',
    width: 15
  },
  table_task_subcontract_parent: {
    width: 55,
    marginLeft: 5,
    flexDirection: 'row',
    backgroundColor: '#E1E1E1',
    boxSizing: 'border-box',
    borderRight: '1pt solid #EBEBEB',
    paddingBottom: 2,
    paddingTop: 2
  },
  table_task_commitment: {
    width: 40,
    marginLeft: 5,
    flexDirection: 'row',
    boxSizing: 'border-box',
    borderRight: '1pt solid #EBEBEB',
    paddingBottom: 2,
    paddingTop: 2
  },
  table_task_commitment_parent: {
    width: 40,
    marginLeft: 5,
    flexDirection: 'row',
    backgroundColor: '#E1E1E1',
    boxSizing: 'border-box',
    borderRight: '1pt solid #EBEBEB',
    paddingBottom: 2,
    paddingTop: 2
  },
  table_task_resource: {
    width: 50,
    marginLeft: 5,
    flexDirection: 'row',
    boxSizing: 'border-box',
    borderRight: '1pt solid #EBEBEB',
    paddingBottom: 2,
    paddingTop: 2
  },
  table_task_resource_parent: {
    width: 50,
    marginLeft: 5,
    flexDirection: 'row',
    backgroundColor: '#E1E1E1',
    boxSizing: 'border-box',
    borderRight: '1pt solid #EBEBEB',
    paddingBottom: 2,
    paddingTop: 2
  },
  table_task_days: {
    width: 19,
    marginLeft: 0,
    flexDirection: 'row',
    boxSizing: 'border-box',
    borderBottom: '1pt solid #EBEBEB',
    borderRight: '1pt solid #EBEBEB',
    paddingBottom: '2px',
    paddingTop: '2px'
  },
  table_task_days_parent: {
    width: 35.25,
    marginLeft: 0,
    flexDirection: 'row',
    boxSizing: 'border-box',
    paddingBottom: 2,
    paddingTop: 2
  },
  table_task_days_end: {
    width: 35.25,
    marginLeft: 0,
    flexDirection: 'row',
    paddingBottom: 2,
    paddingTop: 2
  },
  image_logo: {
    height: '17px',
    width: 'auto',
    marginLeft: '14px',
    alignSelf: 'center'
  },
  image_logo_proplanner: {
    height: 17,
    width: 'auto',
    marginTop: 10,
    marginBottom: 8,
    marginRight: 14,
    alignItems: 'center'
  },
  image_icon_roadblocks: {
    height: 7,
    width: 'auto',
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  pagination: {
    position: 'absolute',
    width: '100%',
    height: 50,
    bottom: 0
  },

  pagination_text: {
    position: 'absolute',
    bottom: 23,
    left: 10,
    // backgroundColor: "red",
    fontSize: 9,
    fontFamily: 'Roboto',
    padding: 7,
    color: '#121212'
  },
  pagination_text_count: {
    position: 'absolute',
    bottom: 23,
    right: 33,
    // backgroundColor: "red",
    fontSize: 9,
    fontFamily: 'Roboto',
    padding: 7,
    color: '#121212'
  }
});
