import { StyleSheet } from '@react-pdf/renderer';
export const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    width: '100%'
  },
  content_activities_tasks: {
    marginLeft: 20,
    marginRight: 20
  },
  title_name: {
    fontSize: 10,
    minHeight: 12,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    color: '#A7A7A7',
    marginTop: 9,
    marginBottom: 9,
    paddingBottom: 0
  },
  answer_name: {
    fontSize: 10,
    minHeight: 12,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    color: '#121212'
  },
  table: {
    flexGrow: 1,
    flexDirection: 'column'
  },
  section_header: {
    flexDirection: 'row',
    marginRight: 20,
    marginLeft: 20,
    marginTop: 20,
    marginBottom: 0,
    border: '1pt solid #EBEBEB',
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    justifyContent: 'space-between'
  },
  section_footer: {
    flexDirection: 'row',
    margin: '0 20 20 20',
    border: 1,
    borderColor: '#EBEBEB',
    borderStyle: 'solid',
    justifyContent: 'space-between'
  },
  section_container_header: {
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 5,
    border: '1pt solid #FAFAFA'
  },
  section_header_table: {
    display: 'flex',
    flexDirection: 'row'
  },
  sub_section_columns_header_table: {
    display: 'flex',
    flex: 1.15,
    flexDirection: 'row'
  },
  sub_section_days_header_table: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row'
  },
  table_header: {
    borderRight: '1pt solid #EBEBEB',
    width: 19
  },
  table_heade_title_name: {
    fontSize: 10,
    fontFamily: 'Roboto',
    color: '#121212',
    textAlign: 'center',
    fontStyle: 'normal',
    fontWeight: 'bold'
    // lineHeight: 12
  },
  table_heade_title_name_date: {
    fontSize: 8,
    fontFamily: 'Roboto',
    textAlign: 'center',
    color: '#121212',
    marginTop: 1,
    fontStyle: 'normal',
    minHeight: 12,
    fontWeight: 'bold',
    lineHeight: 1
  },
  table_task_column: {
    borderLeft: '1pt solid #EBEBEB',
    padding: 0,
    margin: 0,
    height: 18,
    minHeight: 18,
    maxHeight: 18,
    display: 'flex',
    alignItems: 'center'
  },
  table_task_title_name: {
    width: '100%',
    fontSize: 10,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    color: '#121212',
    textAlign: 'center',
    margin: 'auto',
    lineHeight: 1.2
  },
  table_task__subcontract_box: {
    width: 10,
    height: 10,
    position: 'absolute',
    left: 5
  },
  table_task_title_day: {
    width: '100%',
    fontSize: 8,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    textAlign: 'center',
    margin: 'auto',
    lineHeight: 1.2
  },
  section_columns_activity: {
    backgroundColor: '#F5F5F5',
    borderBottom: '1pt solid #EBEBEB',
    borderLeft: '1pt solid #EBEBEB',
    display: 'flex',
    flexDirection: 'row',
    flex: 1.15,
    alignItems: 'center',
    height: 18,
    minHeight: 18,
    maxHeight: 18
  },
  section_row_activity: {
    display: 'flex',
    flexDirection: 'row',
    height: 18,
    minHeight: 18,
    maxHeight: 18,
    overflow: 'hidden'
  },
  section_timeline_activity: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1
  },
  section_timeline_activity_per_day: {
    width: 19.11,
    marginLeft: 0,
    flexDirection: 'row',
    backgroundColor: '#F5F5F5',
    borderBottom: '1pt solid #EBEBEB',
    borderRight: '1pt solid #EBEBEB',
    height: 18,
    minHeight: 18,
    maxHeight: 18
  },
  section_activity_title_name: {
    color: 'black',
    fontWeight: 'bold',
    fontSize: 8,
    fontFamily: 'Roboto',
    paddingLeft: '10px',
    margin: 0,
    flex: 1,
    textAlign: 'left',
    alignSelf: 'center',
    lineHeight: 1.2
  },
  section_task_table: {
    display: 'flex',
    flexDirection: 'row',
    height: 18,
    minHeight: 18,
    maxHeight: 18
  },
  sub_section_columns_task_table: {
    flex: 1.15,
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#CCA7C1',
    boxSizing: 'border-box',
    borderBottom: '1pt solid #EBEBEB',
    borderRight: '1pt solid #EBEBEB',
    height: 18,
    minHeight: 18,
    maxHeight: 18
  },
  sub_section_days_task_table: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row'
  },
  removed_roadblocks: {
    fontSize: 8,
    fontFamily: 'Roboto',
    textAlign: 'center',
    color: '#121212',
    margin: 'auto',
    width: 15
  },
  table_task_days: {
    width: 19,
    marginLeft: 0,
    flexDirection: 'row',
    boxSizing: 'border-box',
    borderBottom: '1pt solid #EBEBEB',
    borderRight: '1pt solid #EBEBEB',
    padding: 0,
    margin: 0,
    height: 18,
    minHeight: 18,
    maxHeight: 18
  },
  image_logo: {
    height: '17px',
    width: 'auto',
    marginLeft: '14px',
    alignSelf: 'center'
  },
  image_logo_proplanner: {
    height: 17,
    width: 'auto',
    marginTop: 10,
    marginBottom: 8,
    marginRight: 14,
    alignItems: 'center'
  },
  image_icon_roadblocks: {
    height: 7,
    width: 'auto',
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
});
